import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'dealer'})
export class DealerPipe implements PipeTransform {
    dealerMap = {
        'br': 'BR Racing',
        'ajd': 'Adrenaline Junkies'
    }

    transform(value: string): string {
        if (this.dealerMap[value]) {
            return this.dealerMap[value];
        }
        console.error('couldn\'t find dealer pipe for ' + value)
        return value;
    }
}
