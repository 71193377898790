import {Component, ViewChild} from '@angular/core';
import {AddonVehicle} from '../car-catalog/car-catalog.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {UsersService} from '../services/users.service';
import {JobService} from '../services/job.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent  {

  constructor(private userService: UsersService, private jobService: JobService) {
    this.userService.getUsers().subscribe(res => {
      let players = res;

      this.jobService.getJobs().subscribe(res => {
        this.jobs = res;
        players.forEach(usr => {
          const jobInfo = this.getJobByNameAndGrade(usr.job, usr.job_grade);
          const player: Player = {
            name: usr.firstName + ' '  + usr.lastName,
            group: usr.group,
            job_label: jobInfo.job_label,
            job_grade_label: jobInfo.grade_label
          };
          this.players.push(player);
        });

        this.admins = this.getUserListForGroup('admin');
        this.adminDataSource = new MatTableDataSource(this.admins);

        this.mods = this.getUserListForGroup('moderator');
        this.moderatorDataSource = new MatTableDataSource(this.mods);


        this.users = this.getUserListForGroup('user');

        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  users: any[] = [];
  admins: any[] = [];
  mods: any[] = [];
  jobs: any[] = [];
  players: any[] = [];
  displayedColumns: string[] = ['name', 'job', 'job_grade'];
  dataSource: MatTableDataSource<Player>;
  adminDataSource: MatTableDataSource<Player>;
  moderatorDataSource: MatTableDataSource<Player>;


  public getJobByNameAndGrade(name: string, grade: number): any {
    let jobInfo;
    this.jobs.forEach(job => {
      if (job.name === name && job.grade === grade) {
        jobInfo = job;
      }
    });
    return jobInfo;
  }

  public getUserListForGroup(group: string): any[] {
    let groupPlayers = [];
    this.players.forEach(usr => {
      if (usr.group === group) {
        groupPlayers.push(usr);
      }
    });
    return groupPlayers;
  }
}

export interface Player {
  name: string;
  group: string;
  job_label: string;
  job_grade_label: string;
}

export interface  User {
  name: string;
  group: string;
  job: string;
  job_grade: number;
}
export interface  Job {
  name: string;
  job_label: string;
  grade: number;
  grade_label: string;
}
