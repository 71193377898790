import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss', './gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor() { }

  pd_images = [2]
  ems_images = [38, 46, 47, 48, 51, 52, 53]
  custom_cars_images = [4, 6, 7, 8, 10, 11, 15, 16, 17, 18, 19, 21, 23, 31, 32, 33]
  mechanic_images = [37]
  images = [];

  ngOnInit(): void {
    this.joinAllImages()
  }


  joinAllImages(){
    this.images = this.images.concat(this.pd_images)
    this.images = this.images.concat(this.custom_cars_images)
    this.images = this.images.concat(this.ems_images)
    this.images = this.images.concat(this.mechanic_images)
  }

  setImageOnModal(image){
    console.log(image)
    document.getElementById("modalImage").setAttribute("src", "/assets/images/in-game/"+image+".png")
    let modal = document.getElementById("myModal");
    modal.style.display = "block";
  }

  closeModal(){
    let modal = document.getElementById("myModal");
    modal.style.display = "none";
  }
  filterImages(value){
    switch(value) {
      case 'all': {
        this.joinAllImages()
        break;
      }
      case 'custom_cars': {
        this.images = this.custom_cars_images;
        break;
      }
      case 'pd': {
        this.images = this.pd_images;
        break;
      }
      case 'ems': {
        this.images = this.ems_images;
        break;
      }
      case 'mechanic': {
        this.images = this.mechanic_images;
        break;
      }
      default: {
        //statements;
        this.joinAllImages()
        break;
      }
    }
  }
}
