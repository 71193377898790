import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from "./configuration.service";

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private http: HttpClient, private config: ConfigurationService) {
    }

    getUsers() {
        return this.http.get<any[]>(this.config.host + '/users');
    }
}
