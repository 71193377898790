import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {HomeComponent} from './home/home.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PdApplicationComponent} from './application/pd-application/pd-application.component';
import {EmsApplicationComponent} from './application/ems-application/ems-application.component';
import {CarCatalogComponent} from './car-catalog/car-catalog.component';
import {UsersComponent} from "./users/users.component";
import {GalleryComponent} from "./gallery/gallery.component";


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'application/pd',
    component: PdApplicationComponent
  },
  {
    path: 'application/ems',
    component: EmsApplicationComponent
  },
  {
    path: 'cars',
    component: CarCatalogComponent
  },
  {
    path: 'players',
    component: UsersComponent
  },
  {
    path: 'gallery',
    component: GalleryComponent
  },
  { path: '',
    component: LandingComponent
  },
  { path: '**', component: PageNotFoundComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
