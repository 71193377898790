import {Component, Input, ViewChild} from '@angular/core';
import {countries} from 'typed-countries';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ApplicationService} from '../../services/application.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-ems-application',
  templateUrl: './ems-application.component.html',
  styleUrls: ['./ems-application.component.css']
})
export class EmsApplicationComponent {
  constructor(private router: Router, private applicationService: ApplicationService) {
    for (let i = 0; i < countries.length; i++) {
      this.countryNames.push(countries[i].name);
    }
  }
  @Input()
  application = {
    irl: {
      firstName: null,
      lastName: null,
      dob: null,
      gender: null,
      country: null,
      discord: null,
      steam: null,
      workingMic: null,
      legalGTA: null,
      findOutAboutUs: null,
      OtherFiveM: null,
    },
    char: {
      firstName: null,
      lastName: null,
      dob: null,
      gender: null,
      occupation: null,
      dl: null,
      felonyConvictions: null,
      bio: null,
      pitch: null,
      reason: null,
      skills: null
    }
};

  public model: any;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  countryNames = [];

  formatter = (result: string) => result;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.countryNames
            : this.countryNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  public submitApplication(): void {
    console.log(this.application);
    const jsonBody = this.application;
    this.applicationService.submitApplication(jsonBody, 'ambulance').subscribe(res => {
      console.log(res);
      const body = res;
      if (body['code'] !== 200) {
        window.alert(`An error occurred.\n Reason: ${res["status"]}\n  Code: ${res["code"]}\n  Please try again.` );
      } else {
        window.alert('Application successfully submitted. Please allow up to 72 hours for review.');
        this.router.navigateByUrl('/home');
      }
      return res;
    });
  }
}
