import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigurationService} from './configuration.service';
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    constructor(private http: HttpClient, private config: ConfigurationService) {
    }

    submitApplication(application, job): Observable<HttpResponse<any>> {
        return this.http.post<HttpResponse<any>>(this.config.host + /application/ + job, application, { headers: {}}).pipe(catchError(this.handleError<any>('submitApplication')));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        // @ts-ignore
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            alert('An unexpected server error has occurred. Try again or contact admin.')
        };
    }
}
