import {Component, Input, OnInit} from '@angular/core';
import {VehicleAddonService} from '../services/vehicle-addon.service';
import {MatDialog} from '@angular/material';
import {CarCatalogOverviewComponent} from './car-catalog-overview/car-catalog-overview.component';
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-car-catalog',
  templateUrl: './car-catalog.component.html',
  styleUrls: ['./car-catalog.component.css']
})
export class CarCatalogComponent implements OnInit {
  loadingData = true
  @Input() forSaleOnly = false;
  addonVehicles;
  filteredVehicles = [];
  paginatedVehicles = [];
  categories = []

  // MatPaginator Inputs
  length = 0;
  pageSize = 15;
  pageSizeOptions: number[] = [15, 30];
  pageIndex = 0;

  //Multi-seclect filters
  categoryDropdownList = [];
  categorySelectedItems = [];
  categoryDropdownSettings = {};
  dealerships = new FormControl();
  dealershipsList = [];
  dealershipFilter = [];

  constructor(private vehicleAddonService: VehicleAddonService, public dialog: MatDialog) {
    this.loadingData = true
    this.vehicleAddonService.getAddonVehicles().subscribe(res => {
      this.addonVehicles = res;
      this.filteredVehicles = this.addonVehicles

      for (let i=0; i<=this.pageSize-1; i++){
        this.paginatedVehicles.push(this.filteredVehicles[i])
      }
      this.length = this.filteredVehicles.length

      this.filteredVehicles.forEach(veh => {
        let catFound = this.categories.find(ob => ob.category === veh.category);
          if(!catFound){
            this.categories.push({category: veh.category, label: veh.label});
          }

        if (!this.dealershipsList.includes(veh.dealer)){
          this.dealershipsList.push(veh.dealer);
        }
        this.dealerships.setValue(this.dealershipsList);
        this.dealershipFilter = this.dealershipsList;
      })

      this.categories.sort((a, b) => (a.label > b.label) ? 1 : -1)

      this.categoryDropdownList = this.categories;
      this.categorySelectedItems = this.categoryDropdownList;
      this.categoryDropdownSettings = {
        singleSelection: false,
        idField: 'category',
        textField: 'label',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        itemsShowLimit: 2,
        allowSearchFilter: true
      };
      this.loadingData = false
    });
  }

  ngOnInit() {
  }

  handleDealershipFilterChange(event){
    this.dealershipFilter = event.value;
  }

  handleFiltersChange() {
    this.loadingData = true
    this.filteredVehicles = []
    this.paginatedVehicles = []
    this.addonVehicles.forEach(veh => {
      let found = this.categorySelectedItems.find(ob => ob.category === veh.category);
      if (found) {
        if (this.dealershipFilter.includes(veh.dealer)) {
          if (this.forSaleOnly) {
            if (veh.for_sale == this.forSaleOnly) {
              this.filteredVehicles.push(veh)
            }
          } else {
            this.filteredVehicles.push(veh)
          }
        }
      }
    })

    if (this.filteredVehicles.length > 0) {
      if (this.filteredVehicles.length < this.pageSize){
        for (let i = 0; i <= this.filteredVehicles.length - 1; i++) {
          this.paginatedVehicles.push(this.filteredVehicles[i])
        }
      }
      else {
        for (let i = 0; i <= this.pageSize - 1; i++) {
          this.paginatedVehicles.push(this.filteredVehicles[i])
        }
      }
    }

    this.length = this.filteredVehicles.length
    this.loadingData = false
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    let startIndex = this.pageSize*this.pageIndex;
    let endIndex = (this.pageSize*this.pageIndex)+this.pageSize-1;

    this.paginatedVehicles = []
    for (let i=startIndex; i<=endIndex; i++){
      if(this.filteredVehicles[i].name) {
        this.paginatedVehicles.push(this.filteredVehicles[i])
      }
    }
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(CarCatalogOverviewComponent,  {
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

export interface  AddonVehicle {
  name: string;
  model: string;
  category: string;
  price: string;
  quantity: string;
  for_sale: boolean;
  image: string;
  description: string;
  dealer: string;
}

