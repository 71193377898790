import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'The Valley';
    route: string;

    constructor(location: Location, router: Router) {
        router.events.subscribe(val => {
            this.route = location.path();
        });
    }


    ngOnInit(): void {
    }
}
