import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from './configuration.service';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    constructor(private http: HttpClient, private config: ConfigurationService) {
    }

    getJobs() {
        return this.http.get<any[]>(this.config.host + '/jobs');
    }
}
