import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor() {
    }
    // host = 'http://localhost:5000/';
    host = 'http://api.thevalleyroleplay.com:5000/';
}
