import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from "./configuration.service";

@Injectable({
    providedIn: 'root',
})
export class VehicleAddonService {
    constructor(private http: HttpClient, private config: ConfigurationService) {
    }
    getAddonVehicles() {
        return this.http.get(this.config.host + '/cars/addon');
    }
}
